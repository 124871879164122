import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import DataTable from "components/Tables/TablesFacturas";

import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";
import axios from "axios";

export default function Facturas() {

  const [horarios, setHorarios] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState("");
  const [pagoId, setPagoId] = useState(0);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage("Por favor selecciona una imagen.");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("pago_id", pagoId);

    console.table(formData);

    try {
      const response = await axiosModificado.post(
        "/subirFactura", // Cambia la URL según tu configuración
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const response2 = await axios.post(
        "https://pagos.kapzar.com/api/subirFactura", // Cambia la URL según tu configuración
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      setMessage("Imagen subida con éxito.");
      console.log(response.data);
    } catch (error) {
      setMessage("Error al subir la imagen.");
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPagoId( value );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get('/obtenerFacturas');
        setHorarios(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
    // Cleanup function
    return () => {
      // Cancelar la solicitud si el componente se desmonta antes de que se complete
    };
  }, []); // Se ejecuta solo una vez al montar el componente

  const columns = [
    {
      Header: 'Identificador',
      accessor: 'pago_id',
    },
    {
      Header: 'Total',
      accessor: 'amount',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ cell: { value } }) => (
        <span>{value === 1 ? "Pagado" : "No Pagado"}</span>
      ),
    }
    
  ];

  if (error) return <p>Ocurrió un error: {error.message}</p>;

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Horarios" />
        <div className="relative bg-lightBlue-600 md:pt-16 pb-16 pt-6">
          <div className="px-4 md:px-10 mx-auto w-full">
            
          </div>
        </div>
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 xl:mb-0 px-4"> {/* aqui se pone cuanto mide ese bloque */}
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Facturas
                    </h3>
                  </div>
                </div>
              </div>
              <div className="block w-full">
                {(loading && !horarios) ? <p>Cargando...</p> : 
                  <DataTable columns={columns} data={horarios} tipo={0} />
                }
              </div>
            </div>
          </div>
          <div className="w-full mb-2 xl:mb-0 px-4">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4 p-4">
                {(loading && !horarios) ? <p>Cargando...</p> : 
                  horarios.map( (opt, index) => {
                    <p>hola</p>
                  })
                }
                <label className="block text-gray-700 text-sm font-bold mb-2">Factura</label>
                <input value={pagoId} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" ></input>
                {pagoId}
              </div>
              <div className="p-4">

                <input type="file" accept="image/*" onChange={handleFileChange} />
                
                {preview && (
                  <div>
                    <p>Vista previa:</p>
                    <img src={preview} alt="Preview" style={{ width: "200px" }} />
                  </div>
                )}
                <hr className="p-2"></hr>
                <button type="submit" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Subir factura</span>
                </button>
                {message && <p>{message}</p>}
              </div>
              
            </form>
          </div>
        </div>


        <div className="px-4 md:px-10 mx-auto w-full">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
