import { WindowScrollController } from '@fullcalendar/core/internal';
import axios from 'axios';
import axiosModificado from 'components/ConfiguracionAxios';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTable, usePagination  } from 'react-table';
import Swal from 'sweetalert2';

const tipos = [ 
  ['tiendas'] ,
  ['usuarios'] ,
  ['rol'] ,
  ['horarios']
];


// Función de manejo de clic en la acción
const handleActionClick = async (row) => {
  try {
    const datatoSend = { aid : row.values.pago_id };
    const resp = await axiosModificado.post(`/pagarFactura`, datatoSend);
    //Swal.fire( resp.data.mensaje , resp.data.description , resp.data.type );
    window.location.reload();
  } catch (error) {
    Swal.fire('Error', error, 'error');
  }
  console.log('Haciendo algo con la fila:', row.values.pago_id);
};

const DataTable = ({ columns, data, tipo }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Nueva propiedad de página
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable({ columns, data }, usePagination);

  
  

  return (
    <>
    <div className='overflow-x-auto '>
      <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
              {/* <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">Acciones</th> */}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
                {/* <td> 
                  <Link key={row.values.id} to={ tipos[tipo] + "/editar/" + row.values.id} className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" >  <i className="fas fa-edit"></i></Link>
                  <button onClick={() => handleActionClick( row , tipo)}  className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" > <i className="fas fa-trash"></i> </button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    <div>
      <button className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={() => previousPage()} disabled={!canPreviousPage}>
        <i className="fas fa-chevron-left"></i>
      </button>{' '}
      <span>
        Página{' '}
        <strong>
          {pageIndex + 1} de {pageOptions.length}
        </strong>{' '}
      </span>
      <button className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={() => nextPage()} disabled={!canNextPage}>
        <i className="fas fa-chevron-right"></i>
      </button>{' '}
    </div>
    </>
    
  );
};



export default DataTable;
